






import { Component, Vue, Watch } from "vue-property-decorator";
import TextComponent from "@/components/TextComponent.vue";
import axios from "axios";
import { privacyPolicy } from "@/interfaces";

@Component({
  name: "PrivacyPolicy",
  components: { TextComponent }
})
export default class About extends Vue {
  mounted() {
    if (!this.privacyPolicy) this.fetchData();
  }

  fetchData() {
    axios.get(this.$store.state.apiUrl + "/privacies").then(response => {
      if (response.status === 200)
        this.$store.commit("setPrivacyPolicy", response.data);
    });
  }

  get privacyPolicy() {
    return this.$store.state.privacyPolicy?.find(
      (x: privacyPolicy) => x.language.language === this.lang
    );
  }

  get lang() {
    return this.$store.state.language;
  }

  @Watch("lang")
  langChanged() {
    this.fetchData();
  }
}
